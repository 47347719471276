












import Vue from 'vue'
import Nav from '@/components/Nav.vue'
import SaleForm from '@/components/SaleForm.vue'
import { Sale } from '@/interfaces'

export default Vue.extend({
  components: {
    Nav,
    SaleForm,
  },
  methods: {
    saleCreated(sale: Sale) {
      if (sale.id) {
        this.$router.push({
          name: 'sale_edit',
          params: {
            saleId: sale.id.toString(),
          },
        })
      }
    },
  },
})
